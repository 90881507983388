import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { useCookies } from "react-cookie";

import Coin from "../pages/Coin";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Page404 from "../pages/Page404";
import WhaleAlert from "../pages/WhaleAlert";
import Cryptocurrencies from "../pages/Cryptocurrencies";
import CryptocurrenciesCategory from "../pages/CryptocurrenciesCategory";
import CryptocurrenciesPagination from "../pages/CryptocurrenciesPagination";
import CryptocurrenciesCategoryPagination from "../pages/CryptocurrenciesCategoryPagination";
import Converter from "../pages/Converter";
import CookieConsent from "../components/CookieConsent";

export default function Index() {
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <BrowserRouter>
      <Header />

      <main>
        <Routes>
          <Route path="/" element={<Cryptocurrencies />} />
          <Route path="/page" element={<Navigate to="/" />} />
          <Route path="/page/:pageNum" element={<CryptocurrenciesPagination />} />

          <Route path="/cryptocurrencies" element={<Navigate to="/" />} />
          <Route path="/cryptocurrencies/:category" element={<CryptocurrenciesCategory />} />
          <Route path="/cryptocurrencies/:category/:pageNum" element={<CryptocurrenciesCategoryPagination />} />

          <Route path="/coin" element={<Navigate to="/" />} />
          <Route path="/coin/:coin_id" element={<Coin />} />

          {/* <Route path="/whale-alert" element={<WhaleAlert />} /> */}

          <Route path="/convert" element={<Converter />} />
          <Route path="/convert/:convert_id" element={<Converter />} />

          <Route path="*" element={<Page404 />} />
        </Routes>
      </main>

      {!cookies.cookieConsent && <CookieConsent />}
      <Footer />
    </BrowserRouter>
  );
}
